.articles {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.col {
  width: 32%;
}

.adrow {
  width: 100%;
  margin-bottom: 1rem;
}

@media screen and (max-width: 900px) {
  .col {
    width: 49%;
  }
}
