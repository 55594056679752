.App {
  background-color: #000000;
  max-width: 1000px;
  margin: auto;
}

.header {
  margin: auto;
  padding-top: 2rem;
}

.header-logo {
  max-height: 70px;
}

.header-text {
  color: white;
  margin: 0px;
  padding: 15px 0;
  font-size: 20px;
  font-weight: bold;
}

.header-logo-img,
.header-logo-text {
  max-height: inherit;
}

.header-logo-text {
  margin-left: 15px;
}

h3 {
  font-family: sans-serif;
}

@media screen and (max-width: 400px) {
  .header {
    text-align: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .header-logo {
    height: 35px;
  }

  .header-logo-img {
    height: inherit;
    display: none;
  }

  .header-logo-text {
    margin: auto;
    width: auto;
    height: inherit;
    padding-left: 1rem;
  }
  .header-text {
    display: none;
  }
}
