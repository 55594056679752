.article {
  margin-bottom: 15px;
  background-color: #ffffff;
  box-shadow: 0px 2px 0px 0px rgba(255, 255, 255, 0.14);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.article-image {
  width: 100%;
}

.article-title,
.article-summary {
  margin-block-start: 1rem;
  margin-block-end: 0rem;
  word-wrap: break-word;
  color: black;
}

.article-title,
.article-summary,
.article-footer {
  padding: 0px 20px 0px 20px;
}

.article-footer {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  margin-top: auto;
}

.article-date {
  font-weight: bold;
}

a {
  text-decoration: none;
  color: black;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-size: medium;
  text-align: left;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}

.side-corner-tag {
  position: relative;
  color: #fff;
  display: inline-block;
  overflow: hidden;
  font-family: Arial, sans-serif;
  font-weight: 900;
}

.side-corner-tag p {
  display: inline;
  /* font-size: 10px; */
}

.side-corner-tag p span {
  position: absolute;
  display: inline-block;
  right: -25px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2),
    inset 0px 5px 30px rgba(255, 255, 255, 0.2);
  text-align: center;
  text-transform: uppercase;
  top: 22px;
  background: #d93131;
  width: 100px;
  padding: 3px 10px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}

.side-corner-tag p:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: -17px;
  right: 69px;
  z-index: -1;
  border: 17px solid;
  border-color: transparent transparent #662121 transparent;
}

.side-corner-tag p:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: 74px;
  z-index: -1;
  right: -10px;
  border: 17px solid;
  border-color: #662121 transparent transparent transparent;
}
