.nextMatch {
  color: white;
  text-align: center;
  border: 1px solid white;
  border-width: 2px 0 2px 0;
  flex-direction: column;
  line-height: 30px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 10px;
}

.nextMatch-longText_channels {
  font-size: small;
  font-style: italic;
  line-height: 20px;
  margin-bottom: 5px;
}

#nextMatch-shortText {
  display: none;
}

#nextMatch-tinyText {
  display: none;
}

@media screen and (max-width: 900px) {
  #nextMatch-longText {
    display: none;
  }
  #nextMatch-shortText {
    display: block;
  }
}

@media screen and (max-width: 600px) {
  #nextMatch-longText {
    display: none;
  }
  #nextMatch-shortText {
    font-size: small;
    display: block;
  }
}

@media screen and (max-width: 550px) {
  #nextMatch-longText {
    display: none;
  }
  #nextMatch-shortText {
    display: none;
  }
  #nextMatch-tinyText {
    display: block;
    font-size: small;
  }
}
